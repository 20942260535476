
  /*
   needs config.resolve.alias['vue$'] = 'vue/dist/vue.esm.js';
   within nuxt.config.js build extend area
  */

  import { Component, Vue, Prop } from 'nuxt-property-decorator';

  @Component
  export default class DynamicTemplateRenderer extends Vue {
    @Prop({ default: '' })
    templateCode!: string;

    get dynamicComponent() {
      return {
        template: this.templateCode,
      };
    }

    render(createElement: any) {
      return createElement(this.dynamicComponent);
    }
  }
