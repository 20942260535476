
  import { Component, Vue, Prop } from 'nuxt-property-decorator';
  import { Navigation } from '@redooo/shared/dist/types/content/navigation';
  import BreakpointValues from '@redooo/shared/dist/types/ui/breakpoints';
  import SiteButton from './SiteButton.vue';
  import IconBase from './IconBase.vue';

  const classes = {
    flyout: 'flyout',
    menuItem: 'menu-item',
    menuItemActive: 'menu-item--active',
    triggerMenuItem: 'menu-item-trigger',
    secondLevel: 'level-2',
    menuBurger: 'menu-burger',
  };

  @Component({
    components: {
      IconBase,
      SiteButton,
    },
  })
  export default class MainNavigation extends Vue {
    $refs!: {
      siteNavi: HTMLElement;
      flyout: HTMLElement;
    };

    open = false;
    secondLevelActive = false;
    isDesktop = false;
    currentBp = '';

    @Prop()
    navItems!: Navigation[];

    @Prop()
    routeInstance!: any;

    @Prop({ default: false })
    isSiteOnMaintenance!: boolean;

    @Prop({ default: false })
    isPortal!: boolean;

    @Prop({ default: () => {} })
    counterData!: Record<string, number>;

    mounted() {
      this.updateViewport();
      this.safeOnWindowResize();

      document.body.addEventListener('click', ({ target }: any) => {
        if (!this.$el.contains(target)) {
          this.deactivateMenuItems();
        }
      });
    }

    get isRouteActive() {
      return this.routeInstance.hash && this.routeInstance.path === '/';
    }

    updateViewport() {
      if (window.innerWidth > BreakpointValues.large) {
        this.isDesktop = true;
      } else {
        this.isDesktop = false;
      }
    }

    forceClosingByResize() {
      if (!this.isDesktop && this.open) {
        this.triggerFlyout();
      } else {
        this.deactivateMenuItems();
      }
      this.updateViewport();
    }

    triggerFlyout() {
      const siteHeaderClasses = document.querySelector('.site-header')?.classList;

      if (!this.open) {
        siteHeaderClasses?.add('site-header--open');
        document.documentElement.classList.add('prevent-scroll');
      } else {
        siteHeaderClasses?.remove('site-header--open');
        document.documentElement.classList.remove('prevent-scroll');
        this.deactivateMenuItems();
      }
      this.open = !this.open;
    }

    closeFlyout() {
      document.querySelector('.site-header')?.classList.remove('site-header--open');
      document.documentElement.classList.remove('prevent-scroll');
      this.deactivateMenuItems();
      this.open = false;
    }

    safeOnWindowResize = () => {
      let resizeTimer: ReturnType<typeof setTimeout>;
      let lastWindowInnerWidth = window.innerWidth;

      window.addEventListener(
        'resize',
        () => {
          clearTimeout(resizeTimer);

          resizeTimer = setTimeout(() => {
            if (window.innerWidth !== lastWindowInnerWidth) {
              if (
                (lastWindowInnerWidth < BreakpointValues.large && window.innerWidth >= BreakpointValues.large) ||
                (window.innerWidth < BreakpointValues.large && lastWindowInnerWidth >= BreakpointValues.large)
              )
                requestAnimationFrame(() => {
                  this.forceClosingByResize();
                  lastWindowInnerWidth = window.innerWidth;
                });
            }
          }, 50);
        },
        { passive: true }
      );
    };

    activateMenuItem({ target }: any) {
      if (this.isDesktop) {
        this.deactivateMenuItems();
        this.applyWrapperHeight(target);
      } else {
        this.secondLevelActive = true;
        target.nextElementSibling?.classList.add('is-active');
        this.$refs.flyout.scrollTop = 0;
      }

      target.parentElement?.classList.add(classes.menuItemActive);
    }

    deactivateMenuItems(levelBack = false) {
      this.$el.querySelector('.level-1')?.querySelector(`.${classes.menuItemActive}`)?.classList.remove(classes.menuItemActive);

      if (this.isDesktop) {
        this.removeWrapperHeight();
      } else if (!this.isDesktop && levelBack) {
        this.secondLevelActive = false;
        setTimeout(() => {
          const level2Menus = this.$el.querySelectorAll('.level-2');
          level2Menus.forEach((menuElement) => {
            menuElement.classList.remove('is-active');
          });
        }, 250);
      }
    }

    applyWrapperHeight(item: HTMLElement) {
      const wrapperHeight = item.parentElement?.querySelector(`.${classes.secondLevel}`)?.clientHeight;
      this.$refs.siteNavi.style.paddingBottom = `${wrapperHeight}px`;
    }

    removeWrapperHeight() {
      if (this.$refs.siteNavi) {
        this.$refs.siteNavi.style.paddingBottom = '';
      }
    }

    setCounterValue(itemSlug: string): number {
      return itemSlug.match(/offers/)
        ? this.counterData.offer
        : itemSlug.match(/disposition/)
          ? this.counterData.disposition
          : itemSlug.match(/documentation/)
            ? this.counterData.documentationOrdersOpen
            : this.counterData.invoiceOpen;
    }

    setShowCounter(itemSlug: string) {
      return (
        (itemSlug.match(/offers/) && this.counterData.offer > 0) ||
        (itemSlug.match(/disposition/) && this.counterData.disposition > 0) ||
        (itemSlug.match(/documentation/) && this.counterData.documentationOrdersOpen > 0) ||
        (itemSlug.match(/invoices/) && this.counterData.invoiceOpen > 0)
      );
    }
  }
