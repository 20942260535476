
  import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator';
  import MainNavigation from './MainNavigation.vue';
  import IconBase from './IconBase.vue';

  @Component({
    components: { MainNavigation, IconBase },
  })
  export default class SiteHeader extends Vue {
    openMetaNavi = false;
    currentMetaLink: string | undefined = '';

    @Prop({ default: false })
    isPortal!: boolean;

    @Prop({ default: 'de' })
    currentI18nLocal!: 'de' | 'en';

    @Prop({ default: () => [] })
    navigationData!: [];

    @Prop({ default: false })
    hideSiteMetaNavi!: boolean;

    mounted() {
      this.updateCurrentMetaLink();
    }

    getWebsiteLink(slug: string) {
      const BASE_URL = 'https://www.redooo.de';
      if (this.currentI18nLocal === 'de') {
        return slug === 'businessCustomer'
          ? `${BASE_URL}/geschaeftskunden`
          : slug === 'privateCustomer'
            ? `${BASE_URL}/privatkunden`
            : `${BASE_URL}/partner`;
      } else {
        return slug === 'businessCustomer'
          ? `${BASE_URL}/en/commercial-customers`
          : slug === 'privateCustomer'
            ? `${BASE_URL}/en/private-customers`
            : `${BASE_URL}/en/partner`;
      }
    }

    updateCurrentMetaLink() {
      if (!this.isPortal) {
        this.currentMetaLink = this.$el.querySelector('.portal-area')?.querySelector('.nuxt-link-active')?.innerHTML;
      } else {
        this.currentMetaLink = this.currentI18nLocal === 'de' ? 'Zur Startseite...' : 'To the home page...';
      }
    }

    triggerMeta(forceClose = false, updateCurrentMetaLink = true) {
      if (!forceClose) this.openMetaNavi = !this.openMetaNavi;
      else this.openMetaNavi = false;

      if (updateCurrentMetaLink) {
        this.updateCurrentMetaLink();

        if (!document.querySelector('.level-2 a.nuxt-link-exact-active')) {
          this.$emit('onResetMainNavigation');
        }
      }
    }

    @Watch('$route')
    onRouteChange(value: string, oldValue: string) {
      if (value !== oldValue) {
        setTimeout(() => {
          this.triggerMeta(true);
        }, 200);
      }
    }
  }
