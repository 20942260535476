
  import { Component, Vue } from 'nuxt-property-decorator';
  import { getLocalizedSiteConfiguration, isKnownLocale } from '@redooo/shared/dist/helpers/content';
  import { masterLocale } from '@redooo/shared/dist/types/content/base';
  import { LOCALE_CODE } from '@redooo/shared/dist/types/generated/contentful';
  import { siteConfigStore } from '../store';
  import SiteFooter from '../components/shared/SiteFooter.vue';
  import IconBase from '../components/shared/IconBase.vue';
  import SiteButton from '../components/shared/SiteButton.vue';
  import LanguageSwitch from '../components/LanguageSwitch.vue';
  import { getCustomerSectionFromRoute, getNavigationObjectByKeyValuePair, getActiveCustomerGroup } from '../utils/navigation';

  @Component({
    components: { IconBase, LanguageSwitch, SiteButton, SiteFooter },
  })
  export default class FooterWrapper extends Vue {
    get currentLocale(): LOCALE_CODE {
      const currLocale = this.$i18n.locale;
      if (isKnownLocale(currLocale)) {
        return currLocale;
      }
      return masterLocale;
    }

    get siteConfiguration() {
      return getLocalizedSiteConfiguration(siteConfigStore.configurations, this.currentLocale);
    }

    get activeCustomerGroup() {
      return getActiveCustomerGroup(this.$route, this.siteConfiguration?.mainNavigation);
    }

    get hideDynamicFooter() {
      return this.$route.name?.includes('shop-order') || this.$route.name?.includes('shop-step');
    }

    get footerStaticNavigationData() {
      const footerStaticNavigationId = getLocalizedSiteConfiguration(siteConfigStore.configurations, this.currentLocale)?.footerStaticNavigationId;
      if (footerStaticNavigationId) {
        const footerStaticNavigationData = getNavigationObjectByKeyValuePair(this.siteConfiguration?.mainNavigation, 'id', footerStaticNavigationId);
        if (footerStaticNavigationData && footerStaticNavigationData.children.length !== 0) {
          return footerStaticNavigationData.children;
        }
      }
    }

    get footerDynamicNavigationData() {
      const footerDynamicNavigationId = getLocalizedSiteConfiguration(siteConfigStore.configurations, this.currentLocale)?.footerDynamicNavigationId;
      if (footerDynamicNavigationId) {
        const footerDynamicNavigationData = getNavigationObjectByKeyValuePair(
          this.siteConfiguration?.mainNavigation,
          'id',
          footerDynamicNavigationId
        );
        if (footerDynamicNavigationData && footerDynamicNavigationData.children.length !== 0) {
          return footerDynamicNavigationData.children;
        }
      }
    }

    get currentNavigationObject() {
      let result;
      const customerSection = getCustomerSectionFromRoute(this.$route);

      if (!this.footerDynamicNavigationData) return;

      if (customerSection) {
        for (const possibleSlug of customerSection) {
          const retrievedNavigationObject = getNavigationObjectByKeyValuePair(this.footerDynamicNavigationData ?? [], 'slug', possibleSlug);
          if (retrievedNavigationObject && retrievedNavigationObject.children.length !== 0) {
            result = retrievedNavigationObject.children;
            break;
          }
        }
      }

      if (result) {
        return result;
      }

      const retrievedNavigationObject = getNavigationObjectByKeyValuePair(this.footerDynamicNavigationData, 'customerGroup', 'commercial');
      if (retrievedNavigationObject && retrievedNavigationObject.children.length !== 0) {
        result = retrievedNavigationObject.children;
      }

      return result;
    }

    get isSiteOnMaintenance(): boolean {
      return !!this.$route.path.match(/(wartungsarbeiten)|(maintenance)/i);
    }
  }
