
  import { Component, Vue } from 'nuxt-property-decorator';
  import IconBase from './shared/IconBase.vue';

  @Component({
    components: { IconBase },
  })
  export default class LoginSwitch extends Vue {
    loginNavigationOpen = false;
    isMounted = false;

    mounted() {
      this.isMounted = true;
      document.body.addEventListener('click', ({ target }: any) => {
        if (!this.$el.contains(target)) {
          this.toggleLoginNavigation(true);
        }
      });
    }

    get getEnvFromOrigin(): string | undefined {
      if (this.isMounted) {
        const match = window.location.origin.match(/-(.[^.]*)/);
        return match ? '-' + match[1] : '';
      }
    }

    toggleLoginNavigation(forceClose = false) {
      if (forceClose) {
        this.loginNavigationOpen = false;
        return;
      }
      this.loginNavigationOpen = !this.loginNavigationOpen;
    }

    setPortalUrl(type: 'kunden' | 'partner'): string {
      const envDevOrQa = this.getEnvFromOrigin;
      return envDevOrQa ? `https://${type}portal${envDevOrQa}.redooo.de` : `https://${type}portal.redooo.de`;
    }

    closePortalFlyout() {
      this.toggleLoginNavigation(true);
      this.$emit('closeFlyout');
    }
  }
