
  import { Component, Vue } from 'nuxt-property-decorator';
  import SiteButton from '../components/shared/SiteButton.vue';
  import { shopStore } from '../store';

  @Component({
    components: {
      SiteButton,
    },
  })
  export default class BasketLink extends Vue {
    get basketItemsAmount() {
      return shopStore.basketItems.length;
    }
  }
