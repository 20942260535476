
  import { Component, Vue, Prop } from 'nuxt-property-decorator';
  import RichText from './RichText.vue';
  import IconBase from './IconBase.vue';

  @Component({
    components: { IconBase, RichText },
  })
  export default class RichTextBox extends Vue {
    mapTypeToIcon = {
      error: 'alert',
      info: 'information',
      success: 'check',
    };

    @Prop({ default: 'info' })
    type!: 'error' | 'info' | 'success';

    @Prop({ default: 'small' })
    size!: 'large' | 'small';

    @Prop({ default: false })
    fitContent!: boolean;

    get icon() {
      return this.mapTypeToIcon[this.type];
    }
  }
