
  import { CookieConsent as CookieConsentData } from '@redooo/shared/dist/types/content/cookieConsent';
  import { getLocalizedSiteConfiguration } from '@redooo/shared/dist/helpers/content';
  import { Component, Vue } from 'nuxt-property-decorator';
  import { sharedStore, siteConfigStore } from '../store';
  import { CookieConsent } from '../store/Shared';
  import RichTextContentfulWrapper from './RichTextContentfulWrapper.vue';
  import IconBase from './shared/IconBase.vue';
  import ModalWindow from './shared/ModalWindow.vue';
  import RichText from './shared/RichText.vue';
  import SiteButton from './shared/SiteButton.vue';

  @Component({
    components: {
      IconBase,
      ModalWindow,
      RichText,
      SiteButton,
      RichTextContentfulWrapper,
    },
  })
  export default class CookieConsentComponent extends Vue {
    $refs!: {
      consentModal: InstanceType<typeof ModalWindow>;
    };

    localeState: CookieConsent = {
      showModal: false,
      necessary: true,
      statistic: false,
    };

    categoryTablesExpanded: { [key: string]: boolean } = {
      necessary: true,
      statistic: true,
    };

    mounted() {
      this.initConsent();
    }

    activated() {
      this.initConsent();
    }

    initConsent() {
      if (this.cookieConsent.showModal) {
        this.$refs.consentModal.open();
      }

      // this.consentData = siteConfigStore.cookieConsent;
      this.localeState = { ...this.cookieConsent };
    }

    // to call modal from outside
    open() {
      this.localeState = { ...this.cookieConsent };
      this.$refs.consentModal.open();
    }

    close() {
      this.$refs.consentModal.close();
    }

    acceptAll() {
      sharedStore.updateCookieConsent({ showModal: false, necessary: true, statistic: true });
      this.close();
    }

    confirmSettings() {
      sharedStore.updateCookieConsent({ ...this.localeState, showModal: false });
      this.close();
    }

    toggleCategoryTable(categoryName: string) {
      this.categoryTablesExpanded[categoryName] = !this.categoryTablesExpanded[categoryName];
    }

    enterTrans(el: HTMLElement) {
      const containerWrapper: HTMLElement | null = el.querySelector('.category-table');

      if (containerWrapper) {
        const contentHeight = containerWrapper.offsetHeight;
        el.style.height = `${contentHeight}px`;
      }
    }

    leaveTrans(el: HTMLElement) {
      el.style.height = `0px`;
    }

    get cookieConsent() {
      return sharedStore.cookieConsent;
    }

    get consentData(): CookieConsentData | undefined {
      return this.siteConfiguration?.cookieConsent;
    }

    get siteConfiguration() {
      return getLocalizedSiteConfiguration(siteConfigStore.configurations, this.$i18n.locale);
    }
  }
