
  import { Component, Prop, Vue } from 'nuxt-property-decorator';
  import { getLocalizedSiteConfiguration, isKnownLocale } from '@redooo/shared/dist/helpers/content';
  import { masterLocale } from '@redooo/shared/dist/types/content/base';
  import { LOCALE_CODE } from '@redooo/shared/dist/types/generated/contentful';
  import BasketLink from '../components/BasketLink.vue';
  import IconBase from '../components/shared/IconBase.vue';
  import MainNavigation from '../components/shared/MainNavigation.vue';
  import SiteButton from '../components/shared/SiteButton.vue';
  import SiteHeader from '../components/shared/SiteHeader.vue';
  import { shopStore, siteConfigStore } from '../store';
  import { getCustomerSectionFromRoute, getNavigationObjectByKeyValuePair } from '../utils/navigation';
  import LoginSwitch from '../components/LoginSwitch.vue';
  import { Navigation } from '~/../shared/dist/types/content/navigation';

  @Component({
    components: { BasketLink, IconBase, LoginSwitch, MainNavigation, SiteButton, SiteHeader },
  })
  export default class HeaderWrapper extends Vue {
    $refs!: {
      mainNavigation: InstanceType<typeof MainNavigation>;
    };

    @Prop({ default: false })
    isShopLayout!: boolean;

    closeFlyout() {
      this.$refs.mainNavigation?.closeFlyout();
    }

    resetMainNavigation() {
      this.$refs.mainNavigation?.deactivateMenuItems(true);
    }

    get currentLocale(): LOCALE_CODE {
      const currLocale = this.$i18n.locale;
      if (isKnownLocale(currLocale)) {
        return currLocale;
      }
      return masterLocale;
    }

    get siteConfiguration() {
      return getLocalizedSiteConfiguration(siteConfigStore.configurations, this.currentLocale);
    }

    get navigationData() {
      return getLocalizedSiteConfiguration(siteConfigStore.configurations, this.currentLocale)?.mainNavigation;
    }

    get contactPageSlug() {
      const contactNavigationId = this.siteConfiguration?.contactNavigationId;
      const retrievedNavigationObject = getNavigationObjectByKeyValuePair(this.navigationData, 'id', contactNavigationId);
      if (retrievedNavigationObject) {
        return retrievedNavigationObject.slug;
      }
    }

    get showBasketLink() {
      return this.siteConfiguration && !this.$route.name?.includes('shop-order') && this.hasBasketItems && !this.isSiteOnMaintenance;
    }

    get hasBasketItems() {
      return shopStore.basketItems.length > 0;
    }

    get getCurrentUserType() {
      return siteConfigStore.getCurrentUserType;
    }

    get metaNavigationData() {
      const siteConfiguration = getLocalizedSiteConfiguration(siteConfigStore.configurations, this.currentLocale);
      if (!siteConfiguration) {
        return [];
      }

      const metaNavigationId = siteConfiguration.metaNavigationId;

      if (metaNavigationId) {
        const metaNavigationData = getNavigationObjectByKeyValuePair(this.navigationData, 'id', metaNavigationId);
        if (metaNavigationData && metaNavigationData.children.length !== 0) {
          return metaNavigationData.children;
        }
      }
    }

    get currentNavigationObject() {
      let result;
      const customerSection = getCustomerSectionFromRoute(this.$route);
      if (customerSection) {
        //* Used when the page is a contentful page.
        result = this.retrievedNavigationObjectChildrens(customerSection);
      } else {
        //* Used when the page is not a contentful page.
        result = this.retrievedNavigationObjectChildrens([`/${this.getCurrentUserType}`]);
      }

      if (result) return result;

      const retrievedNavigationObject = getNavigationObjectByKeyValuePair(this.navigationData, 'customerGroup', 'commercial');
      if (retrievedNavigationObject && retrievedNavigationObject.children.length !== 0) {
        result = retrievedNavigationObject.children;
      }

      return result;
    }

    get isSiteOnMaintenance(): boolean {
      return !!this.$route.path.match(/(wartungsarbeiten)|(maintenance)/i);
    }

    private retrievedNavigationObjectChildrens(sections: Array<string>): Navigation[] | undefined {
      for (const possibleSlug of sections) {
        const retrievedNavigationObject = getNavigationObjectByKeyValuePair(this.navigationData, 'slug', possibleSlug);
        if (retrievedNavigationObject && retrievedNavigationObject.children.length !== 0) {
          return retrievedNavigationObject.children || undefined;
        }
      }
    }
  }
