
  import { Component, Vue, Prop } from 'nuxt-property-decorator';
  import { Block, Document, Inline, INLINES, BLOCKS } from '@contentful/rich-text-types';
  import { CommonNode, documentToHtmlString, Next } from '@contentful/rich-text-html-renderer';
  import sanitizeHtml from 'sanitize-html';
  import { getSlugForNavigationId, getLocalizedSlug, isKnownLocale } from '@redooo/shared/dist/helpers/content';
  import { LOCALE_CODE } from '@redooo/shared/dist/types/generated/contentful';
  import { masterLocale } from '@redooo/shared/dist/types/content/base';
  import logger from '@redooo/shared/dist/services/logging';
  import RichText from '../components/shared/RichText.vue';
  import DynamicTemplateRenderer from '../components/shared/DynamicTemplateRenderer.vue';

  import { siteConfigStore } from '../store';

  @Component({
    components: { RichText, DynamicTemplateRenderer },
  })
  export default class RichTextContentfulWrapper extends Vue {
    @Prop()
    richTextDocument?: Document;

    get currentLocale(): LOCALE_CODE {
      const currLocale = this.$i18n.locale;
      if (isKnownLocale(currLocale)) {
        return currLocale;
      }
      return masterLocale;
    }

    get renderRichTextOptions() {
      const unknownType = `-- Unknown Type --`;
      return {
        renderNode: {
          [INLINES.ENTRY_HYPERLINK]: (node: Block | Inline, children: Next) => {
            const navigationId = node.data.target.sys.id;
            const slug = getSlugForNavigationId(siteConfigStore.configurations, navigationId);
            const localizedSlug = getLocalizedSlug(siteConfigStore.configurations, slug ?? '', this.currentLocale);
            if (localizedSlug) {
              return `<nuxt-link to="${localizedSlug}">${children(node.content as CommonNode[])}</nuxt-link>`;
            } else {
              return `${children(node.content as CommonNode[])}`;
            }
          },
          [INLINES.HYPERLINK]: (node: Block | Inline, children: Next) => {
            return `<a href="${node.data.uri}" class="external-link" rel="noopener noreferrer" target="_blank">${children(node.content as CommonNode[])}</a>`;
          },
          [INLINES.ASSET_HYPERLINK]: () => unknownType,
          [BLOCKS.EMBEDDED_ASSET]: () => unknownType,
        },
      };
    }

    get renderedRichText() {
      let sanitizedHTML = '';
      if (this.richTextDocument) {
        try {
          const convertedHTML = documentToHtmlString(this.richTextDocument as any, this.renderRichTextOptions);
          const convertedHTMLWithStyles = convertedHTML
            .replace(/>{green}/g, ' style="color:#1eb340;">')
            .replace(/>{red}/g, ' style="color:#db2133;">');
          sanitizedHTML = sanitizeHtml(convertedHTMLWithStyles, {
            allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'a', 'b', 'i', 'ol', 'ul', 'li', 'p', 'nuxt-link'],
            allowedAttributes: {
              a: ['href', 'name', 'target', 'class', 'rel'],
              h3: ['style'],
              p: ['style'],
              b: ['style'],
              'nuxt-link': ['to'],
            },
          });
          sanitizedHTML = this.tidyHTML(sanitizedHTML);
        } catch (e) {
          logger.warn('richtext cannot be rendered correctly');
          sanitizedHTML = 'Invalid RichText content';
        }
      }
      return `<div class="rich-text-contentful-wrapper component">${sanitizedHTML}</div>`;
    }

    tidyHTML(html: string) {
      return html
        .replace(/\n/g, '<br />')
        .replace(/\{\{.*?\}\}/g, '')
        .replace(/<p>\s*<\/p>/g, '');
    }
  }
