
  import { Component, Vue, Prop } from 'nuxt-property-decorator';
  import { IconName } from '@redooo/shared/dist/types/ui/iconName';
  import { ButtonPriority } from '@redooo/shared/dist/types/ui/buttonPriority';
  import LoadingIndicator from './LoadingIndicator.vue';
  import IconBase from './IconBase.vue';

  @Component({
    components: {
      LoadingIndicator,
      IconBase,
    },
  })
  export default class SiteButton extends Vue {
    @Prop({ default: 'button' })
    tag!: 'button' | 'anchor';

    @Prop({ default: 'primary' })
    priority!: ButtonPriority;

    @Prop()
    icon?: IconName;

    @Prop({ default: 'right' })
    iconPosition!: 'left' | 'right';

    @Prop({ default: false })
    showLoader!: boolean;

    @Prop({ default: false })
    disabled!: boolean;

    @Prop()
    tooltip?: string;

    get generateClasses() {
      return [
        `btn-${this.priority}`,
        `icon-position-${this.iconPosition}`,
        `icon-${this.icon}`,
        { 'is-loading': this.showLoader },
        { 'icon-only': !this.$slots.default },
      ];
    }
  }
