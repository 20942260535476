
  import { Component, Vue, Prop } from 'nuxt-property-decorator';
  import SiteButton from './SiteButton.vue';

  @Component({
    components: {
      SiteButton,
    },
  })
  export default class ModalWindow extends Vue {
    @Prop({ default: 'default' })
    size!: 'default' | 'large';

    @Prop({ default: true })
    showCloseButton!: boolean;

    @Prop({ default: 'dialog' })
    ariaRole!: 'dialog' | 'alertdialog';

    isActive = false;
    currentActiveDOMElement: HTMLElement | undefined = undefined;

    open() {
      this.isActive = true;
      this.currentActiveDOMElement = document.activeElement as HTMLElement;
      document.documentElement.classList.add('prevent-scroll');
    }

    close(trigger = 'default') {
      if (trigger !== 'default' && !this.showCloseButton) {
        return;
      }

      this.isActive = false;
      document.documentElement.classList.remove('prevent-scroll');
      if (this.currentActiveDOMElement) {
        this.currentActiveDOMElement.focus();
      }
    }

    keyPress(event: KeyboardEvent) {
      if (this.isActive) {
        if (event.key === 'Escape' || event.key === 'Esc') {
          this.close('escapeKey');
        }

        if (event.key === 'Tab') {
          const focusable = this.findFocusable();
          const firstFocusable = focusable[0];
          const lastFocusable = focusable[focusable.length - 1];

          if (Array.from(focusable).includes(event.target as HTMLElement)) {
            if (event.shiftKey) {
              event.preventDefault();
              lastFocusable.focus();
            } else {
              event.preventDefault();
              firstFocusable.focus();
            }
          }
        }
      }
    }

    created() {
      if (typeof window !== 'undefined') {
        document.addEventListener('keydown', this.keyPress);
      }
    }

    beforeDestroy() {
      if (typeof window !== 'undefined') {
        document.removeEventListener('keydown', this.keyPress);
        document.documentElement.classList.remove('prevent-scroll');
      }
    }

    findFocusable() {
      return this.$el.querySelectorAll<HTMLElement>(`a[href]:not([tabindex="-1"]),
                                     area[href],
                                     input:not([disabled]),
                                     select:not([disabled]),
                                     textarea:not([disabled]),
                                     button:not([disabled]),
                                     iframe,
                                     object,
                                     embed,
                                     *[tabindex]:not([tabindex="-1"]),
                                     *[contenteditable]`);
    }
  }
