
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import { NuxtError } from '@nuxt/types';
  import { ErrorType } from '../types/errorTypes';
  import SiteButton from '../components/shared/SiteButton.vue';
  import { trackPageView } from '../utils/track-page-view';

  @Component({
    components: {
      SiteButton,
    },
  })
  export default class ErrorLayout extends Vue {
    @Prop({ type: Object, required: true }) readonly error!: NuxtError;
    // error.message === 'no page' -> route not found

    head() {
      return {
        title: this.error.statusCode + ` - ${this.getPageTitle}`,
      };
    }

    mounted() {
      // Track page-view
      setTimeout(() => {
        trackPageView(this.$gtm, this.error.statusCode + ` - ${this.getPageTitle}`, this.$route.path, `${this.$route.name}`);
      }, 1000);
    }

    get getPageTitle() {
      switch (this.error.statusCode) {
        case 404:
          return this.$t('pages.errorLayout.pageTitles.noPage');
        default:
          return this.$t('pages.errorLayout.pageTitles.default');
      }
    }

    get getHeadline() {
      switch (this.error.message) {
        case ErrorType.NOT_AVAILABLE:
          return this.$t('pages.errorLayout.headline.notAvailable');
        default:
          return this.$t('pages.errorLayout.headline.default');
      }
    }

    get getSubHeadline() {
      switch (this.error.message) {
        default:
          return this.$t('pages.errorLayout.subHeadline.default');
      }
    }

    get getBtnText() {
      switch (this.error.message) {
        default:
          return this.$t('pages.errorLayout.btnText.default');
      }
    }

    pageRefresh() {
      switch (this.error.message) {
        default:
          this.$router.go(0);
      }
    }

    backToHomepage() {
      return this.$router.push('/');
    }
  }
