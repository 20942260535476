
  import { Component, Vue } from 'nuxt-property-decorator';
  import CookieConsent from '../components/CookieConsent.vue';
  import FooterWrapper from '../components/FooterWrapper.vue';
  import HeaderWrapper from '../components/HeaderWrapper.vue';
  import updateLayoutBodyClass from '../utils/update-layout-body-class';
  import RichTextBox from '../components/shared/RichTextBox.vue';
  import { shopStore } from '../store';

  @Component({
    components: {
      CookieConsent,
      FooterWrapper,
      HeaderWrapper,
      RichTextBox,
    },
  })
  export default class DefaultLayout extends Vue {
    $refs!: {
      consentModal: InstanceType<typeof CookieConsent>;
    };

    activated() {
      updateLayoutBodyClass('layout-default');
    }

    mounted() {
      updateLayoutBodyClass('layout-default');
    }

    get isHttpApiErrorOccurred() {
      return shopStore.isHttpApiLoadingErrorOccurred;
    }

    callCookieModal() {
      // TODO: nur für debug
      this.$refs.consentModal.open();
    }
  }
