
  import { Component, Vue } from 'nuxt-property-decorator';
  import CookieConsent from '../components/CookieConsent.vue';
  import FooterWrapper from '../components/FooterWrapper.vue';
  import HeaderWrapper from '../components/HeaderWrapper.vue';
  import updateLayoutBodyClass from '../utils/update-layout-body-class';

  @Component({
    components: {
      CookieConsent,
      FooterWrapper,
      HeaderWrapper,
    },
  })
  export default class ShopLayout extends Vue {
    activated() {
      updateLayoutBodyClass('layout-shop');
    }

    mounted() {
      updateLayoutBodyClass('layout-shop');
    }
  }
