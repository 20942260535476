
  import { Component, Vue } from 'nuxt-property-decorator';
  import { LOCALE_CODE } from '@redooo/shared/dist/types/generated/contentful';
  import { getSlug } from '@redooo/shared/dist/helpers/content';

  import { LocaleObject } from 'nuxt-i18n/types';
  import { siteConfigStore } from '../store';
  import SiteButton from './shared/SiteButton.vue';

  @Component({
    components: { SiteButton },
  })
  export default class LanguageSwitch extends Vue {
    currentLocale = '';

    mounted() {
      this.currentLocale = this.$i18n.locale;
    }

    activated() {
      this.currentLocale = this.$i18n.locale;
    }

    get path() {
      return this.$route.path;
    }

    get locales() {
      return siteConfigStore.masterConfiguration?.locales ?? [];
    }

    getLocaleName(localeCode: LOCALE_CODE) {
      const localeObject = (this.$i18n.locales as LocaleObject[]).find((locale) => locale.code === localeCode);
      return localeObject ? localeObject.name : 'n/a';
    }

    redirectToSlugIfExists(targetLocale: LOCALE_CODE) {
      let targetSlug = getSlug(siteConfigStore.configurations, this.path, this.$i18n.locale, targetLocale);

      if (!targetSlug) {
        // is there a configured page route?
        targetSlug = this.switchLocalePath(targetLocale);
      } else {
        targetSlug = this.localePath(targetSlug, targetLocale);
      }

      if (targetSlug) {
        this.$router.push({ path: targetSlug });
      }
    }

    switchToLocale(locale: LOCALE_CODE) {
      this.redirectToSlugIfExists(locale);
    }
  }
