
  import { Component, Vue, Prop } from 'nuxt-property-decorator';
  import SiteButton from './SiteButton.vue';

  @Component({
    components: { SiteButton },
  })
  export default class SiteFooter extends Vue {
    currentYear = new Date().getFullYear();

    @Prop({ default: true })
    showLogo!: boolean;
  }
